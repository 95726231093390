import React, { createContext, useState } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  //Hook para las alertas (Snackbar)
  const [open, setOpen] = useState(false);
  //Hook para los msg de las alertas
  const [snackbarMsg, setSnackbarMsg] = useState("");
  //Hook para el estado de las alertas
  const [snackbarState, setSnackbarState] = useState("warning");

  const handleSnackbar = () => {
    setOpen(true);
  };

  // Función para agregar un producto al carrito
  const addToCart = (product, quantity) => {
    // Verifica si el producto ya está en el carrito
    const existingItem = cartItems.find(
      (item) => item.productPLU === product.productPLU
    );

    if (existingItem) {
      // Si el producto ya está en el carrito, incrementa la cantidad según la cantidad seleccionada
      const updatedCartItems = cartItems.map((item) => {
        if (item.productPLU === product.productPLU) {
          return { ...item, cartQuantity: item.cartQuantity + quantity };
        }
        return item;
      });
      setCartItems(updatedCartItems);
    } else {
      // Si el producto no está en el carrito, agrégalo con la cantidad seleccionada
      setCartItems((prevItems) => [
        ...prevItems,
        { ...product, cartQuantity: quantity },
      ]);
    }

    console.log("listado:", cartItems);
  };

  // Función para actualizar la cantidad de un producto en el carrito
  const updateCart = (productPLU, quantity) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.productPLU === productPLU) {
        return { ...item, cartQuantity: quantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  // Función para eliminar un producto del carrito
  const removeFromCart = (productPLU) => {
    // Implementa la lógica para eliminar un producto del carrito
    const updatedCartItems = cartItems.filter(
      (item) => item.productPLU !== productPLU
    );
    setCartItems(updatedCartItems);
  };

  // Función para obtener la cantidad total de productos en el carrito
  const getTotalItems = () => {
    return cartItems.length;
  };

  const removeAllFromCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        getTotalItems,
        updateCart,
        removeAllFromCart,
        open,
        setOpen,
        handleSnackbar,
        snackbarMsg,
        setSnackbarMsg,
        snackbarState,
        setSnackbarState,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
