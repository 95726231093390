import React, { useContext, useState } from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CartContext } from "../components/context/CartContext";
import Navbar from "../components/navbar/Navbar";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom"; // Importa useNavigate y useLocation

const CartView = () => {
  const {
    cartItems,
    removeFromCart,
    updateCart,
    removeAllFromCart,
    getTotalItems,
    handleSnackbar,
    setSnackbarMsg,
    setSnackbarState,
  } = useContext(CartContext);
  const [selectedQuantity, setSelectedQuantity] = useState({});
  const [openDialog, setOpenDialog] = useState(false); // Estado para controlar la apertura del diálogo
  const [openDialogItem, setOpenDialogItem] = useState(false); // Estado para controlar la apertura del diálogo
  const [productToDelete, setProductToDelete] = useState(null);
  const [emptyList, setEmptyList] = useState(getTotalItems() === 0);
  const navigate = useNavigate(); // Obtiene la función navigate

  // Función para eliminar un producto del carrito
  const handleDeleteItem = () => {
    removeFromCart(productToDelete.productPLU);
    setOpenDialogItem(false);
    setSnackbarState("warning");
    setSnackbarMsg(`Producto eliminado`);
    handleSnackbar();
  };

  const handleOpenDialogItem = (item) => {
    setProductToDelete(item);
    setOpenDialogItem(true);
  };

  const handleCloseDialogItem = () => {
    setOpenDialogItem(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Función para borrar todos los productos del carrito
  const handleDeleteAll = () => {
    removeAllFromCart();
    setOpenDialog(false);
    setOpenDialogItem(false);
    setSnackbarState("warning");
    setSnackbarMsg(`Carrito vaciado`);
    handleSnackbar();
  };

  // Función para actualizar la cantidad seleccionada
  const handleQuantityChange = (productPLU, quantity) => {
    if (quantity < 1) return;

    // Obtiene el ítem del carrito actual
    const updatedItemIndex = cartItems.findIndex(
      (item) => item.productPLU === productPLU
    );

    if (updatedItemIndex !== -1) {
      // Clona el array de ítems del carrito para evitar mutar el estado directamente
      const updatedCartItems = [...cartItems];

      // Actualiza la cantidad del producto en el carrito
      updatedCartItems[updatedItemIndex].cartQuantity = quantity;

      // Actualiza el carrito con los ítems actualizados
      updateCart(updatedCartItems);
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
    });
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total +=
        (selectedQuantity[item.productPLU] || item.cartQuantity) *
        item.precioVenta;
    });
    if (total > 0) return formatPrice(total);
    else return "$0";
  };

  return (
    <>
      <Navbar title="Carrito" backButton isCartView={true} />{" "}
      {/* Indica que estamos en la vista del carrito */}
      <Container sx={{ marginTop: "100px" }}>
        <div
          style={{
            position: "fixed",
            top: "10%",
            right: 0,
            marginRight: "20px",
            zIndex: 1000, // Asegura que esté por encima del contenido
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={handleOpenDialog}
            startIcon={<RemoveShoppingCartIcon />}
            sx={{ fontSize: "0.6rem", minWidth: "100px", padding: "8px" }} // Ajusta el tamaño aquí según tus preferencias
          >
            Borrar todo
          </Button>
        </div>
        {emptyList ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "50vh",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Todavía no ha añadido nada a su carrito
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/`)}
            >
              Volver a productos
            </Button>
          </div>
        ) : (
          <List sx={{ flexGrow: 1, overflowY: "auto", marginBottom: "50px" }}>
            {cartItems.map((item) => (
              <React.Fragment key={item.productPLU}>
                <Divider variant="inset" component="li" />

                <ListItem alignItems="flex-start" disablePadding>
                  <ListItemAvatar>
                    <Avatar
                      alt={item.productName}
                      src={`https://spx.rtitec.com.ar:8080/api/products/image/${item.productPLU}/front`}
                      sx={{ width: 80, height: 80 }} // Ajusta el tamaño aquí según tus preferencias
                    />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ marginLeft: "30px" }}
                    primary={
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontWeight: "bold", lineHeight: "1.4rem" }}
                      >
                        {item.productName}
                      </Typography>
                    }
                    secondary={
                      <div style={{ textAlign: "center", marginTop: "7%" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              handleQuantityChange(
                                item.productPLU,
                                (selectedQuantity[item.productPLU] ||
                                  item.cartQuantity) - 1
                              )
                            }
                            aria-label="Decrementar cantidad"
                            sx={{
                              borderRadius: "50%",
                              width: "32px",
                              height: "32px",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                            }}
                          >
                            -
                          </IconButton>
                          <span
                            style={{
                              width: "64px",
                              height: "32px",
                              borderRadius: "4px",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: "0 10px",
                            }}
                          >
                            {selectedQuantity[item.productPLU] ||
                              item.cartQuantity}
                          </span>
                          <IconButton
                            onClick={() =>
                              handleQuantityChange(
                                item.productPLU,
                                (selectedQuantity[item.productPLU] ||
                                  item.cartQuantity) + 1
                              )
                            }
                            aria-label="Incrementar cantidad"
                            sx={{
                              borderRadius: "50%",
                              width: "32px",
                              height: "32px",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                            }}
                          >
                            +
                          </IconButton>
                        </div>
                        <span
                          style={{
                            textAlign: "center",
                            fontSize: "1.2rem",
                            color: "green",
                          }}
                        >
                          {formatPrice(
                            (selectedQuantity[item.productPLU] ||
                              item.cartQuantity) * item.precioVenta
                          )}
                        </span>
                      </div>
                    }
                  />
                  <IconButton
                    color="error"
                    onClick={() => handleOpenDialogItem(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        )}
      </Container>
      {/* Total siempre visible */}
      <div
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "#3f51b5",
          color: "#fff",
          padding: "10px 0",
          borderTop: "1px solid #ccc",
        }}
      >
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => navigate(`/`)}
          sx={{ fontSize: "0.6rem", minWidth: "100px", padding: "8px" }} // Ajusta el tamaño aquí según tus preferencias
        >
          <ArrowBackIcon />
          <span>Productos</span>
        </Button>
        <Typography sx={{ fontWeight: "800", fontSize: "1.2rem" }}>
          Total: {calculateTotal()}
        </Typography>
      </div>
      {/* Diálogo de Borrar Todo */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          ¿Desea borrar todos los productos del carrito?
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="textSecondary">
            Esta acción es irreversible.
          </Typography>
          {/* Contenido opcional */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleDeleteAll} color="error" autoFocus>
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Diálogo de Borrar Item */}
      <Dialog open={openDialogItem} onClose={handleCloseDialogItem}>
        <DialogTitle>
          {productToDelete && `¿Desea borrar ${productToDelete.productName}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialogItem}>Cancelar</Button>
          <Button onClick={handleDeleteItem} color="error" autoFocus>
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CartView;
