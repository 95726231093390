import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import ProductView from "./pages/ProductView";
import CartView from "./pages/CartView";
import CustomizedSnackbars from "./components/Snackbar/Snackbar";
import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <CustomizedSnackbars />

      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/product/:plu" element={<ProductView />} />
        <Route path="/cart" element={<CartView />} />

        {/* Agregamos la ruta para el carrito */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
