import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
} from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import AddToCartButton from "../components/buttonGroup/AddtoCartButton";
import { CartContext } from "../components/context/CartContext";

const ProductView = () => {
  const { product } = useLocation().state;
  const [cartQuantity, setQuantity] = React.useState(1);
  const navigate = useNavigate();
  const [offer, setOffer] = React.useState(false);
  const { addToCart, handleSnackbar, setSnackbarMsg, setSnackbarState } =
    React.useContext(CartContext);

  React.useEffect(() => {
    console.log(product);
    if (product.precioPromo > 0) setOffer(true);
  }, []);

  // Función para incrementar la cantidad
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // Función para decrementar la cantidad
  const decrementQuantity = () => {
    if (cartQuantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCart = () => {
    addToCart(product, cartQuantity);
    console.log("Agregado al carrito:", product, "Cantidad:", cartQuantity);
    setSnackbarState("success");
    setSnackbarMsg(`${product.productName} agregado al carrito`);
    handleSnackbar();
    navigate('/')
  };

  const handleUnit = () => {
    let perUnit;
    if (product.cantidadUnitaria == "0") {
      perUnit = "0";
      return;
    }
    if (
      product.unidad === "L" ||
      product.unidad === "KG" ||
      product.unidad === "UN" ||
      product.unidad === "M"
    ) {
      perUnit = (
        parseFloat(product.precioVenta.toString()) /
        parseFloat(product.cantidadUnitaria)
      ).toFixed(2);
    } else {
      perUnit = (
        (parseFloat(product.precioVenta.toString()) /
          parseFloat(product.cantidadUnitaria)) *
        100
      ).toFixed(2);
    }
    return perUnit;
  };

  let x = "";

  if (product?.unidad != null) {
    if (
      product?.unidad === "L" ||
      product?.unidad === "KG" ||
      product?.unidad === "UN" ||
      product?.unidad === "M"
    ) {
      x = "";
    } else {
      x = "100";
    }
  } else {
    x = "";
  }
  product.perUnit = handleUnit();

  const formatPrice = (price) => {
    if (price > 0) {
      return price.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    } else {
      return "No hay cantidad en el producto";
    }
  };

  return (
    <>
      <Navbar title="Detalles" backButton />
      <Container sx={{ marginTop: "80px" }}>
        <div
          style={{
            margin: "auto",
            marginBottom: "10px",
            width: "15rem",
            padding: "3%",
          }}
        >
          <img
            src={`https://spx.rtitec.com.ar:8080/api/products/image/${product.productPLU}/front`}
            alt={product.productName}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "600px",
              objectFit: "contain",
            }}
          />
        </div>
        <Typography
          variant="subtitle2"
          component={"p"}
          sx={{ color: "#515151" }}
        >
          {product.productName}
        </Typography>
        <Typography
          variant="subtitle1"
          component={"p"}
          sx={{
            color: "#0480e2",
            fontSize: "1.4rem",
            lineHeight: "unset",
            margin: "10px 0 20px 0",
          }}
        >
          {product.productDesc}.
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "x-large",
            marginBottom: "20px",
            borderTop: "1px solid #00000038",
            borderBottom: "1px solid #00000038",
            padding: "10px 0",
          }}
        >
          {offer ? (
            <>
              <div>
                <span
                  style={{
                    marginRight: "10px",
                    textDecoration: "line-through",
                    fontWeight: "100",
                    color: "#0480e2",
                  }}
                >
                  {formatPrice(product.precioVenta)}
                </span>
                <span
                  style={{
                    color: "#ed1d24",
                    fontWeight: "bolder",
                    fontSize: "inherit",
                  }}
                >
                  Oferta!
                </span>
              </div>
              <h1 style={{ margin: "0", color: "#ed1d24" }}>
                {formatPrice(product.precioPromo)}
              </h1>
            </>
          ) : (
            <h1 style={{ margin: "0", color: "#0480e2" }}>
              {formatPrice(product.precioVenta)}
            </h1>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="caption">
              Cantidad: {product.cantidadUnitaria} {product.unidad}
            </Typography>
            <Typography variant="caption" style={{ color: "#515151" }}>
              ({formatPrice(parseFloat(product.perUnit))} x {x} {product.unidad}
              .)
            </Typography>
          </div>

          <Typography variant="caption">Ean: {product.productPLU}</Typography>

          <div
            style={{
              display: "flex",
              alignItems: "end",
              margin: "10px 0",
              borderBottom: "1px solid #00000038",
            }}
          >
            <Typography variant="caption">
              Ultima modificación:{" "}
              {new Date(product.updatedAt).toLocaleDateString("es-ES")}
            </Typography>
          </div>
          <AddToCartButton
            quantity={cartQuantity}
            incrementQuantity={incrementQuantity}
            decrementQuantity={decrementQuantity}
            addToCart={handleAddToCart}
          />
        </div>
      </Container>
    </>
  );
};

export default ProductView;
