import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Container,
  Typography,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import Navbar from "../components/navbar/Navbar";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState(
    sessionStorage.getItem("currentTerm") || ""
  );
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem("currentPage")) || 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    sessionStorage.setItem("currentPage", page);
    sessionStorage.setItem("currentTerm", searchTerm);
    console.log(searchResults);
  }, [searchTerm, page]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      let url;
      if (/^\d+$/.test(searchTerm)) {
        // buscar por PLU
        url = `https://spx.rtitec.com.ar:8080/api/products?plu=${searchTerm}&sort=NO_PLU&limit=6&page=${page}`;
        // url = `https://192.168.0.201:8080/api/products?plu=${searchTerm}&sort=NO_PLU&limit=6&page=${page}`;
        // `https://31.40.212.188:8080/api/products/?name=${wildcard}${search}&plu=&weight=S&sort=c_plu&limit=12&page=${page}`
      } else {
        //buscar por nombre
        const encodedSearchTerm = encodeURIComponent(`%${searchTerm}`);
        url = `https://spx.rtitec.com.ar:8080/api/products?name=${encodedSearchTerm}&sort=NO_PLU&limit=6&page=${page}`;
        // url = `https://192.168.0.201:8080/api/products?name=${encodedSearchTerm}&sort=NO_PLU&limit=6&page=${page}`;
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        if (response.data.rows === null) {
          setSearchResults([]);
        } else {
          setTotalPages(response.data.totalPages);
          setSearchResults(response.data.rows);
        }

        setError(false);
      } else {
        console.error("Error al obtener los productos:", response.statusText);
        setErrorMsg(response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Error al obtener los productos:", error.message);
      setErrorMsg(error.message);
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const resetSearch = () => {
    setPage(1);
    setSearchTerm("");
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const verProducto = (product) => {
    navigate(`/product/${product.productPLU}`, { state: { product } });
    console.log(product);
  };

  return (
    <>
      <Navbar title="Productos" />
      <Container sx={{marginTop:'80px'}}>
        <TextField
          sx={{ marginTop: "10px" }}
          fullWidth
          placeholder="Busca por nombre o num PLU"
          variant="outlined"
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <Button
                onClick={resetSearch}
                color="inherit"
                sx={{ cursor: "pointer" }}
              >
                <CancelIcon />
              </Button>
            ),
          }}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20rem",
            }}
          >
            <CircularProgress size="5rem" color="primary" />
          </div>
        ) : (
          <List>
            {!error ? (
              searchResults.length !== 0 ? (
                searchResults.map((product) => (
                  <>
                    <Divider component="li" />

                    <ListItem
                      key={parseInt(product.productPLU)}
                      sx={{ padding: "10px 0 10px 0", cursor: "pointer" }}
                      onClick={() => verProducto(product)}
                    >
                      {/* IMAGEN */}
                      <div style={{ width: "40%", height: "6rem" }}>
                        <img
                          src={`https://spx.rtitec.com.ar:8080/api/products/image/${product.productPLU}/front`}
                          alt={product.productName}
                          onError={(e) => {
                            e.target.src =
                              "https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png";
                          }}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>

                      {/* DESCRIPCION ITEM */}
                      <div
                        style={{
                          width: "100%",
                          borderLeft: "1px #00000033 solid",
                          marginLeft: "3%",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="h6"
                              component="h6"
                              sx={{
                                color: "#0480e2",
                                fontWeight: "bold",
                                lineHeight: "normal",
                              }}
                            >
                              {product.productName}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              component="p"
                              sx={{ color: "#02457a" }}
                            >
                              {product.productDesc}
                            </Typography>
                          }
                          sx={{ marginLeft: "3%" }}
                        />
                        <ListItemText
                          primary={
                            product.precioPromo > 0 ? (
                              <span
                                style={{ color: "#ed1d24", fontWeight: "bold" }}
                              >
                                ${product.precioPromo.toFixed(2)}
                              </span>
                            ) : (
                              <span
                                style={{ color: "#0480e2", fontWeight: "bold" }}
                              >
                                ${product.precioVenta.toFixed(2)}
                              </span>
                            )
                          }
                          secondary={
                            product.precioPromo > 0 ? (
                              <span
                                style={{
                                  color: "#0480e2",
                                  textDecoration: "line-through",
                                }}
                              >
                                ${product.precioVenta.toFixed(2)}
                              </span>
                            ) : null
                          }
                          sx={{ marginLeft: "3%" }}
                        />

                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              component="p"
                              sx={{ color: "#02457a" }}
                            >
                              Ean: {product.productPLU}
                            </Typography>
                          }
                          sx={{ marginLeft: "3%" }}
                        />
                      </div>
                    </ListItem>
                  </>
                ))
              ) : (
                <h2>
                  No se encontro el producto con la busqueda "{searchTerm}"
                </h2>
              )
            ) : (
              <h1 style={{ color: "red" }}>{errorMsg}</h1>
            )}
          </List>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page}
            siblingCount={0}
            color="primary"
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      </Container>
    </>
  );
};

export default Index;
