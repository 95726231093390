import React, { useContext } from "react";
import { AppBar, Toolbar, IconButton, Typography, Badge } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CartContext } from "../context/CartContext";
import { useLocation, useNavigate } from "react-router-dom"; // Importa useNavigate y useLocation

const Navbar = ({ title }) => {
  const { getTotalItems } = useContext(CartContext);
  const navigate = useNavigate(); // Obtiene la función navigate
  const location = useLocation(); // Obtiene la ubicación actual

  const navigateBack = () => {
    navigate(-1); // Función navigate para retroceder una página
  };

  const navigateToCart = () => {
    navigate("/cart"); // Redirige al usuario a la vista del carrito
  };
  

  const renderBackButton = () => {
    if (location.pathname !== "/") {
      return (
        <IconButton edge="start" color="inherit" aria-label="back" onClick={navigateBack}>
          <ArrowBackIcon/>
        </IconButton>
      );
    }
    // Renderiza un botón oculto si la ruta es "/"
    return (
      <IconButton edge="start" color="inherit" aria-label="back" style={{ visibility: "hidden", opacity: 0 }}>
        <ArrowBackIcon/>
      </IconButton>
    );
  };

  const renderCartIcon = () => {
    if (location.pathname !== "/cart") {
      return (
        <IconButton color="inherit" aria-label="cart" onClick={navigateToCart}>
          <Badge badgeContent={getTotalItems()} color="error">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      );
    } else {
      // Renderiza el icono del carrito como invisible si estás en la página del carrito
      return (
        <IconButton
          color="inherit"
          aria-label="cart"
          style={{ visibility: "hidden", opacity: 0 }}
        >
          <Badge badgeContent={getTotalItems()} color="error">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      );
    }
  };
  

  return (
    <AppBar position="fixed">
      <Toolbar>
        {renderBackButton()}
        <Typography variant="h6" style={{ flexGrow: 1, textAlign:'center'}}>
          {title}
        </Typography>
        {renderCartIcon()}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
