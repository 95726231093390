import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const AddToCartButton = ({
  quantity,
  incrementQuantity,
  decrementQuantity,
  addToCart,
}) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent:'center', marginBottom:'7%', marginTop:'5%' }}>
        <IconButton
          onClick={decrementQuantity}
          aria-label="Decrementar cantidad"
          sx={{
            borderRadius: "50%",
            width: "32px",
            height: "32px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
          }}
        >
          <RemoveIcon />
        </IconButton>
        <div
          style={{
            width: "64px",
            height: "32px",
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 10px",
          }}
        >
          <Typography variant="body1">{quantity}</Typography>
        </div>
        <IconButton
          onClick={incrementQuantity}
          aria-label="Incrementar cantidad"
          sx={{
            borderRadius: "50%",
            width: "32px",
            height: "32px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <Button
        variant="contained"
        color="inherit"
        onClick={addToCart}
        startIcon={<ShoppingCartIcon />}
      >
        Agregar al carrito
      </Button>
    </>
  );
};

export default AddToCartButton;
